import {Pipe, PipeTransform} from '@angular/core';
import { Car } from '../entities/car';

@Pipe({
  name: 'filterPk'
})
export class FilterPkPipe implements PipeTransform {

  transform(cars: any, minPK: number, maxPK: number): any {
    if (Number.isNaN(minPK))
      minPK = 0;
    if (Number.isNaN(maxPK))
      maxPK = Infinity;

    if (!cars)
      return cars;
    if ((minPK && minPK <= 0) && (maxPK && maxPK <= 0))
      return cars;

    return cars.filter((car: Car) =>
      (car.horsePower >= minPK && car.horsePower <= maxPK)
      || (car.horsePower <= maxPK && (!minPK || minPK <= 0))
      || (car.horsePower >= minPK && (!maxPK || maxPK <= 0)));
  }

}
