import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'search'
})
@Injectable()
export class SearchPipe implements PipeTransform {
  transform(items: any[], fields: string[], value: string): any[] {
    if (!items) {
      return [];
    }
    if (!fields || !value) {
      return items;
    }

    return items.filter(singleItem => {
        for (let i = 0; i < fields.length; i++) {
          if (singleItem[fields[i]].toLowerCase().includes(value.toLowerCase())) {
            return true;
          }
        }
        return false;
      }
    );
  }
}
