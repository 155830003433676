import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filtercylindercapacity'
})
export class FiltercylindercapacityPipe implements PipeTransform {

  transform(cars: any, cylinder: string): any {
    if (!cars) {
      return cars;
    } else {
      const to1 = 'to1';
      const to2 = 'to1.3';
      const to3 = 'to2';
      const to4 = 'to3';
      const to5 = 'from3.1';

      if (!cylinder) {
        return cars;
      } else if (cylinder !== undefined) {
        return cars.filter(function (car) {
          const cylinderCapacity = parseInt(car.cylinderCapacity, 10);

          switch (cylinder) {
            case to1: {
              const tolCheck = cylinderCapacity <= 1000;
              if (tolCheck) {
                return cars;
              }
              break;
            }
            case to2: {
              const toTwoCheck = cylinderCapacity > 1000 && cylinderCapacity <= 1300;
              if (toTwoCheck) {
                return cars;
              }
              break;
            }
            case to3: {
              const toTwoCheck = cylinderCapacity > 1300 && cylinderCapacity <= 2000;
              if (toTwoCheck) {
                return cars;
              }
              break;
            }
            case to4: {
              const toThreeCheck = cylinderCapacity > 2000 && cylinderCapacity <= 3000;
              if (toThreeCheck) {
                return cars;
              }
              break;
            }
            case to5: {
              const fromThreeOneCheck = cylinderCapacity > 3000;
              if (fromThreeOneCheck) {
                return cars;
              }
              break;
            }
          }
        });
      }
    }
  }

}
