import {Component, OnInit} from '@angular/core';
import {AccountService} from '../../services/Account.service';
import {Router, NavigationEnd} from '@angular/router';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  private cookieMessage: any = 'Op deze pagina wordt er gebruik gemaakt van cookies zie de ';
  private cookieDismiss: any = 'Accepteer';
  private cookieLinkText: any = 'privacy voorwaarden';


  constructor(private readonly accountService: AccountService, public router: Router) {
    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'UA-242531270-1',
            {
              'page_path': event.urlAfterRedirects
            }
          );
        }
      }
    );
  }

  ngOnInit(): void {
    const cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: '#164969'
        },
        button: {
          background: '#ffe000',
          text: '#164969'
        }
      },
      theme: 'classic',
      content: {
        message: this.cookieMessage,
        dismiss: this.cookieDismiss,
        link: this.cookieLinkText,
        href: '/dataprivacy'
      }
    });
  }

  public logout(): void {
    this.accountService.logout();
  }

}
