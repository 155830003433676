import {Pipe, PipeTransform} from '@angular/core';
import { Car } from '../entities/car';

@Pipe({
  name: 'filterKm'
})
export class FilterKmPipe implements PipeTransform {

  transform(cars: any, minKM: number, maxKM: number): any {
    if (Number.isNaN(minKM))
        minKM = 0;
    if (Number.isNaN(maxKM))
        maxKM = Infinity;

    if (!cars)
      return cars;
    if ((minKM && minKM <= 0) && (maxKM && maxKM <= 0))
      return cars;

    return cars.filter((car: Car) =>
      (car.mileage >= minKM && car.mileage <= maxKM)
      || (car.mileage <= maxKM && (!minKM || minKM <= 0))
      || (car.mileage >= minKM && (!maxKM || maxKM <= 0)));
  }

}