import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";

import { BehaviorSubject, Subject } from "rxjs";
import { Account } from "../entities/Account";

import { AppConstants } from "../app.constants";
import { LoginResponse } from "../login-response.model";
import { Tokens } from "../entities/Tokens";
import { SettingsImpl } from "../settings.model";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({ providedIn: "root" })
export class AccountService {
  errorText: boolean;
  readonly loginUrl = AppConstants.BASE_URL + "/tcleasing/login";
  readonly logoutUrl = AppConstants.BASE_URL + "/tcleasing/logout";
  readonly tokenUrl = AppConstants.BASE_URL + "/tcleasing/token";
  readonly settingsChanged = new Subject<Tokens>();
  filter = new BehaviorSubject(false);

  constructor(private readonly http: HttpClient) {}

  login(account: Account) {
    return this.http
      .post<LoginResponse>(this.loginUrl, JSON.stringify(account), httpOptions)
      .subscribe(
        (data) => this.handleLoginResponse(data),
        (err) => this.handleLoginError(err)
      );
  }

  public logout(): void {
    this.doLogout();
    this.clearStorage();
  }

  protected updateSettings(user: string, token: string): void {
    const userToken = this.retrieve();
    userToken.user = user;
    userToken.token = token;

    this.persist(userToken);

    if (userToken.user != null) {
      window.location.assign("./administrator-page");
    } else {
      window.location.reload();
    }
  }

  protected clearStorage(): void {
    localStorage.removeItem(AppConstants.LOCAL_STORAGE_KEY_SETTINGS);

    this.settingsChanged.next();
    window.location.assign("./");
  }

  private doLogout() {
    return this.http
      .post(this.logoutUrl, this.retrieve().user, httpOptions)
      .subscribe();
  }

  getErrorText(): boolean {
    return this.errorText;
  }

  private persist(userToken: Tokens): void {
    localStorage.setItem(
      AppConstants.LOCAL_STORAGE_KEY_SETTINGS,
      JSON.stringify(userToken)
    );
    this.settingsChanged.next(userToken);
  }

  retrieve(): Tokens {
    const json = localStorage.getItem(AppConstants.LOCAL_STORAGE_KEY_SETTINGS);
    if (json) {
      return JSON.parse(json);
    } else {
      return new SettingsImpl();
    }
  }

  setErrorText(value: boolean) {
    this.errorText = value;
  }

  private handleLoginResponse(response: LoginResponse): void {
    this.setErrorText(false);
    this.updateSettings(response.user, response.token);
  }

  private handleLoginError(err: HttpErrorResponse) {
    const statusCode = 401;
    const location = "/login";
    if (err.status === statusCode) {
      this.setErrorText(true);
      // window.location.assign(location);
    }
  }

  checkToken() {
    const token = this.retrieve().token;
    const url = `${this.tokenUrl}?key=${token}`;
    return this.http.get<string[]>(url);
  }
}
