import {Tokens} from './entities/Tokens';

export class SettingsImpl implements Tokens {
  user: string;
  token: string;

  constructor() {
    this.user = '';
    this.token = '';
  }
}
