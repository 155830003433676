import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filtercolour'
})
export class FiltercolourPipe implements PipeTransform {

  transform(cars: any, colour: string): any {
    if (!cars) {
      return cars;
    } else {
      if (!colour) {
        return cars;
      } else if (colour !== undefined) {
        return cars.filter(function (car) {
          return car.colour.toLowerCase().includes(colour.toLowerCase());
        });
      }
    }
  }
}
