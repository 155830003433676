import {Component, OnInit} from '@angular/core';
@Component({
  selector: 'app-data-privacy',
  templateUrl: './data-privacy.component.html',
  styleUrls: ['./data-privacy.component.css'],
})
export class DataPrivacyComponent implements OnInit {
  ngOnInit(): void {
  }

}
