import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AddCarComponent} from './components/add-car/add-car.component';
import {CarGalleryComponent} from './components/car-gallery/car-gallery.component';
import {AdministratorPageComponent} from './components/administrator-page/administrator-page.component';
import {CardetailsPageComponent} from './components/cardetails-page/cardetails-page.component';
import {LoginComponent} from './components/login/login.component';
import {CarLeasecalculatorComponent} from './components/car-leasecalculator/car-leasecalculator.component';
import {EditPageComponent} from './components/edit-page/edit-page.component';
import {DataPrivacyComponent} from './components/data-privacy/data-privacy.component';

const routes: Routes = [
  {path: '', redirectTo: '/page/1', pathMatch: 'full'},
  {path: 'page/:id', component: CarGalleryComponent},
  {path: 'add-car', component: AddCarComponent},
  {path: 'administrator-page', component: AdministratorPageComponent},
  {path: 'login', component: LoginComponent},
  {path: 'administrator-page', component: AdministratorPageComponent},
  {path: 'car-details/:carId', component: CardetailsPageComponent},
  {path: 'car-leasecalculator/:carId', component: CarLeasecalculatorComponent},
  {path: 'edit-page/:carId', component: EditPageComponent},
  {path: 'dataprivacy', component: DataPrivacyComponent}
];

@NgModule({
  exports: [RouterModule],
  imports: [CommonModule, RouterModule.forRoot(routes)]
})
export class AppRoutingModule {
}
