import {Component, OnInit} from '@angular/core';
import {CarService} from '../../services/car.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {AppConstants} from '../../app.constants';
import {AccountService} from '../../services/Account.service';
import {LicenseplateService} from '../../services/licenseplate.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.css']
})
export class EditPageComponent implements OnInit {

  selectedStatus: number;
  sold: number;
  cars: Object;
  alertPrice: boolean;
  images: string[] = [];
  user: string;
  imageChangedEvent: any = '';
  croppedImage: any = null;
  beingCropped = false;
  firstPhoto: any;

  loading = false;

  constructor(
    private readonly carService: CarService,
    private carRoute: ActivatedRoute,
    private readonly accountService: AccountService,
    private router: Router,
    private licenseplateService: LicenseplateService
  ) {
  }

  ngOnInit() {
    this.getCarsById('');
    this.getImagesById();

    const local = localStorage.getItem(AppConstants.LOCAL_STORAGE_KEY_SETTINGS);
    if (local != null) {
      this.user = local;
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.setFirstPhoto(this.croppedImage);
  }

  imageLoaded() {
  }

  loadImageFailed() {
  }

  addCarImage() {
    if (this.croppedImage !== null || this.croppedImage !== '') {
      this.images.push(this.croppedImage);
      this.imageChangedEvent = '';
      this.croppedImage = null;
      this.beingCropped = false;
      $('#imagePicker').val('');
    }
  }

  openImagePicker() {
    $('#imagePicker').click();
    this.beingCropped = true;
  }

  deleteImage(image: any) {
    this.images.splice(image, 1);
  }

  setFirstPhoto(firstPhoto: any): void {
    this.firstPhoto = firstPhoto;
  }

  async onUpload(registrationNumber: string): Promise<any> {
    const givenImages = {
      'registrationNumber': registrationNumber,
      'files': this.images
    };
    await this.carService.deleteImages(registrationNumber);
    await this.carService.uploadFiles(givenImages);
    await this.carService.uploadThumbnail(givenImages.files[0], registrationNumber);
  }

  getCarsById(status: string): void {
    const carId = +this.carRoute.snapshot.paramMap.get('carId');
    this.carService.getCar(carId, status)
      .subscribe(cars => {
        cars.cars[0].summary = cars.cars[0].summary.replace(/\ufffd/g, '€').replace('�', '€');
        this.cars = cars;
        this.selectedStatus = cars.cars[0].sold;
      });
  }

  getImagesById(): void {
    const carId = +this.carRoute.snapshot.paramMap.get('carId');
    this.carService.getImage(carId)
      .subscribe(images => {
        this.images = images.images;
      });
  }

  changeCarStatus(event: any): void {
    this.selectedStatus = event.target.value;
  }

  async editData(carId: number, registrationNumber: string, prijs: string, summary: string, checkbox: any, online: any) {
    this.loading = true;
    const price = parseInt(prijs, 10);
    await this.editMinimumPrice(carId, price);
    await this.editVehicleSummary(carId, summary);
    await this.editSold(carId, this.selectedStatus);
    await this.editOnlineCheckbox(carId, online, checkbox);
    await this.onUpload(registrationNumber).catch((err) => this.loading = false);
    await this.router.navigateByUrl('/administrator-page').finally(() => this.carService.getCarsWithoutImages(''));
  }

  async editOnlineCheckbox(carId: number, online: boolean, checkbox: any) {
    await this.carService.editOnlineCheckbox(carId, online, checkbox)
      .subscribe(() => {}, err => this.handleTokenError(err));
  }

  async editSold(carId: number, sold: number) {
    await this.carService.editSold(carId, sold)
      .subscribe(() => {}, err => this.handleTokenError(err));
  }

  async editMinimumPrice(carId: number, minimumPrice: number) {
    if (minimumPrice >= 0) {
      await this.carService.editMinimumPrice(carId, minimumPrice)
        .subscribe(() => {}, err => this.handleTokenError(err));
    } else if (minimumPrice < 0) {
      this.alertPrice = true;
    }
  }

  async editVehicleSummary(carId: number, vehicleSummary: string) {
    await this.carService.editVehicleSummary(carId, vehicleSummary)
      .subscribe(() => {}, err => this.handleTokenError(err));
  }

  editVehicleCategory(carId: number, vehicleCategory: string): void {
    this.carService.editVehicleCategory(carId, vehicleCategory)
      .subscribe(cars => this.cars = cars, err => this.handleTokenError(err));
  }

  editColour(carId: number, colour: string) {
    this.carService.editColour(carId, colour)
      .subscribe(cars => this.cars = cars, err => this.handleTokenError(err));
  }

  editUnladenMass(carId: number, unladenMassOfVehicle: number) {
    this.carService.editUnladenMass(carId, unladenMassOfVehicle)
      .subscribe(cars => this.cars = cars, err => this.handleTokenError(err));
  }

  editExpiralDateAPK(carId: number, expiralDateAPK: string) {
    expiralDateAPK = expiralDateAPK.replace(/-/g, '');
    this.carService.editExpiralDateAPK(carId, expiralDateAPK)
      .subscribe(cars => this.cars = cars, err => this.handleTokenError(err));
  }

  editCylinderCapacity(carId: number, cylinderCapacity: number) {
    this.carService.editCylinderCapacity(carId, cylinderCapacity)
      .subscribe(cars => this.cars = cars, err => this.handleTokenError(err));
  }

  editNumberOfCylinders(carId: number, numberOfCylinders: number) {
    this.carService.editNumberOfCylinders(carId, numberOfCylinders)
      .subscribe(cars => this.cars = cars, err => this.handleTokenError(err));
  }

  toggleInfo() {
    const info = $('#info');
    const btn = $('#toggleInfo');
    info.toggleClass('open');
    btn.text(
      info.hasClass('open')
        ? 'Sluiten'
        : 'RDW-Informatie');
  }

  formatDate(val: string) {

    return val
      .match(/(\d{4})(\d{2})(\d{2})/)
      .slice(1)
      .reverse()
      .join('-');
  }

  GetSidecodeLicenseplate(Licenseplate) {
    return this.licenseplateService.GetSidecodeLicenseplate(Licenseplate);
  }

  FormatLicenseplate(Licenseplate, Sidecode) {
    return this.licenseplateService.FormatLicenseplate(Licenseplate, Sidecode);
  }
  private handleTokenError(err: HttpErrorResponse) {
    const statusCode = 401;
    const location = AppConstants.BASE_URL + '/login?error=Token_expired';
    if (err.status === statusCode) {
      this.accountService.logout();
      window.location.assign(location);
    }
  }

}
