import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filtertrade'
})
export class FiltertradePipe implements PipeTransform {

  transform(cars: any, trade: string): any {
    if (!cars) {
      return cars;
    } else {
      if (!trade) {
        return cars;
      } else if (trade !== undefined) {
        return cars.filter(function (car) {
          return car.tradeName.toLowerCase().includes(trade.toLowerCase());
        });
      }
    }
  }

}
