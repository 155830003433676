import {Component, OnInit} from '@angular/core';
import {CarService} from '../../services/car.service';
import {Car} from '../../entities/car';

@Component({
  selector: 'app-car-carousel',
  templateUrl: './car-carousel.component.html',
  styleUrls: ['./car-carousel.component.css']
})
export class CarCarouselComponent implements OnInit {

  constructor(private readonly carService: CarService) {
  }

  cars: Car[];

  ngOnInit() {
    this.carService.getCarsTop5().subscribe(cars => this.cars = cars);
  }


}
