export class Car {
  carId: number;

  constructor(public registrationNumber: string,
              public vehicleCategory: string,
              public brand: string,
              public tradeName: string,
              public colour: string,
              public numberOfSeats: number,
              public numberOfDoors: number,
              public expiralDateAPK: string,
              public dateOfFirstPermission: string,
              public cylinderCapacity: string,
              public numberOfCylinders: string,
              public unladenMassOfVehicle: number,
              public fuel: string,
              public summary: string,
              public acquisitionValue: number,
              public sold: number,
              public photoURL: string,
              public online: number,
              public date_of_addition: string,
              public isFavourite: boolean,
              public horsePower: number,
              public carType: number,
              public mileage: number
  ) {
  }
}
