import {Component, Input, OnInit} from '@angular/core';
import {Car} from '../../entities/car';
import {CarService} from '../../services/car.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-favourite-car',
  templateUrl: './favourite-car.component.html',
  styleUrls: ['./favourite-car.component.css']
})
export class FavouriteCarComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  email: String;
  emailSend = false;

  constructor(private carService: CarService, private formBuilder: FormBuilder) {
  }

  @Input() favouriteCars: Car[];
  @Input() favouritesList: number[];

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;
  }

  sendEmail(email: String) {
    if (this.registerForm.invalid) {
      return;
    }

    this.carService.sendEmailFavouriteCars(this.favouritesList, email);
    this.emailSend = true;
  }

  emailSendOk() {
    this.emailSend = false;
  }
}

