import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class LicenseplateService {
  constructor() {
  }

  GetSidecodeLicenseplate(Licenseplate: string) {

    const arrSC = [];
    let scUitz: string;

    Licenseplate = Licenseplate.replace('-', '').toUpperCase();

    arrSC[0] = /^[a-zA-Z]{2}[\d]{2}[\d]{2}$/; // XX-99-99
    arrSC[1] = /^[\d]{2}[\d]{2}[a-zA-Z]{2}$/; // 99-99-XX
    arrSC[2] = /^[\d]{2}[a-zA-Z]{2}[\d]{2}$/; // 99-XX-99
    arrSC[3] = /^[a-zA-Z]{2}[\d]{2}[a-zA-Z]{2}$/; // XX-99-XX
    arrSC[4] = /^[a-zA-Z]{2}[a-zA-Z]{2}[\d]{2}$/; // XX-XX-99
    arrSC[5] = /^[\d]{2}[a-zA-Z]{2}[a-zA-Z]{2}$/; // 99-XX-XX
    arrSC[6] = /^[\d]{2}[a-zA-Z]{3}[\d]$/; // 99-XXX-9
    arrSC[7] = /^[\d][a-zA-Z]{3}[\d]{2}$/; // 9-XXX-99
    arrSC[8] = /^[a-zA-Z]{2}[\d]{3}[a-zA-Z]$/; //  XX-999-X
    arrSC[9] = /^[a-zA-Z][\d]{3}[a-zA-Z]{2}$/;  // X-999-XX
    arrSC[10] = /^[a-zA-Z]{3}[\d]{2}[a-zA-Z]$/; // XXX-99-X
    arrSC[11] = /^[a-zA-Z][\d]{2}[a-zA-Z]{3}$/; // X-99-XXX
    arrSC[12] = /^[\d][a-zA-Z]{2}[\d]{3}$/; // 9-XX-999
    arrSC[13] = /^[\d]{3}[a-zA-Z]{2}[\d]$/; // 999-XX-9

    // except licenseplates for diplomats
    scUitz = '^CD[ABFJNST][0-9]{1,3}$'; // for example: CDB1 of CDJ45

    for (let i = 0; i < arrSC.length; i++) {
      if (Licenseplate.match(arrSC[i])) {
        return i + 1;
      }
    }
    if (Licenseplate.match(scUitz)) {
      return 'CD';
    }
    return false;
  }

  FormatLicenseplate(Licenseplate: string, Sidecode: number) {

    Licenseplate = Licenseplate.replace('-', '').toUpperCase();

    if (Sidecode <= 6) {
      return Licenseplate.substr(0, 2) + '-' + Licenseplate.substr(2, 2) + '-' + Licenseplate.substr(4, 2);
    }
    if (Sidecode === 7 || Sidecode === 9) {
      return Licenseplate.substr(0, 2) + '-' + Licenseplate.substr(2, 3) + '-' + Licenseplate.substr(5, 1);
    }
    if (Sidecode === 8 || Sidecode === 10) {
      return Licenseplate.substr(0, 1) + '-' + Licenseplate.substr(1, 3) + '-' + Licenseplate.substr(4, 2);
    }
    if (Sidecode === 11 || Sidecode === 14) {
      return Licenseplate.substr(0, 3) + '-' + Licenseplate.substr(3, 2) + '-' + Licenseplate.substr(5, 1);
    }
    if (Sidecode === 12 || Sidecode === 13) {
      return Licenseplate.substr(0, 1) + '-' + Licenseplate.substr(1, 2) + '-' + Licenseplate.substr(3, 3);
    }
    return Licenseplate;
  }
}
