import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Car } from "../entities/car";
import { Tokens } from "../entities/Tokens";
import { AppConstants } from "../app.constants";
import { SettingsImpl } from "../settings.model";
import { CarService } from "./car.service";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class RDWService {
  private readonly RDWUrl = AppConstants.BASE_URL + "/tcleasing/cars/add-car";

  constructor(
    private readonly http: HttpClient,
    private readonly carService: CarService
  ) {}

  sendData(car: Car) {
    const token = this.retrieve().token;
    const url = `${this.RDWUrl}?key=${token}`;
    return this.http.post(url, JSON.stringify(car), httpOptions);
  }

  private retrieve(): Tokens {
    const json = localStorage.getItem(AppConstants.LOCAL_STORAGE_KEY_SETTINGS);
    if (json) {
      return JSON.parse(json);
    } else {
      return new SettingsImpl();
    }
  }
}
