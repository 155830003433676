import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterfuel'
})
export class FilterfuelPipe implements PipeTransform {

  transform(cars: any, fuel: string): any {
    if (!cars) {
      return cars;
    } else {
      if (!fuel) {
        return cars;
      } else if (fuel !== undefined) {
        return cars.filter(function (car) {
          return car.fuel.toLowerCase().includes(fuel.toLowerCase());
        });
      }
    }
  }

}
