import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterdateofAPK'
})
export class FilterdateofAPKPipe implements PipeTransform {


  transform(cars: any, apk: string): any {
    if (!cars) {
      return cars;
    } else {
      const sixMonthsInMilliseconds = 15552000000;
      const oneYearInMilliseconds = 31104000000;
      const apkHalfYear = 'apk6months';
      const apkWholeYear = 'apk1year';

      if (!apk) {
        return cars;
      } else if (apk !== undefined) {
        return cars.filter(function (car) {
          const expiralDateAPK1 = car.expiralDateAPK.toString();
          const expiralDateAPK = convertDateFormat(expiralDateAPK1);
          switch (apk) {
            case apkHalfYear: {
              if ((expiralDateAPK.getTime() - new Date().getTime()) > sixMonthsInMilliseconds) {
                return cars;
              }
              break;
            }
            case apkWholeYear: {
              if ((expiralDateAPK.getTime() - new Date().getTime()) > oneYearInMilliseconds) {
                return cars;
              }
              break;
            }
            default: {
              return cars;
            }
          }
        });
      }
    }
  }
}

function convertDateFormat(dateToFormat): Date {

  const arrayFirst = 0;
  const arraySecond = 1;
  const arraythird = 2;

  const splitted = dateToFormat.split('/');
  return new Date(
    splitted[arraythird] - arrayFirst,
    splitted[arraySecond] - arraySecond,
    splitted[arrayFirst] - arrayFirst
  );
}
