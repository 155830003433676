import {Pipe, PipeTransform} from '@angular/core';
import { Car } from '../entities/car';

@Pipe({
  name: 'filterunladenmass'
})
export class FilterunladenmassPipe implements PipeTransform {

  transform(cars: any, minMass: number, maxMass: number): any {
    if (Number.isNaN(minMass))
      minMass = 0;
    if (Number.isNaN(maxMass))
      maxMass = Infinity;

    if (!cars)
      return cars;
    if ((minMass && minMass <= 0) && (maxMass && maxMass <= 0))
      return cars;

    return cars.filter((car: Car) =>
      (car.unladenMassOfVehicle >= minMass && car.unladenMassOfVehicle <= maxMass)
      || (car.unladenMassOfVehicle <= maxMass && (!minMass || minMass <= 0))
      || (car.unladenMassOfVehicle >= minMass && (!maxMass || maxMass <= 0)));
  }

}
