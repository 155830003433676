import { Component, OnInit } from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {CarService} from '../../services/car.service';

@Component({
  selector: 'app-photocropper',
  templateUrl: './photocropper.component.html',
  styleUrls: ['./photocropper.component.css']
})
export class PhotocropperComponent implements OnInit {

  croppedImage: any = '';
  imageChangedEvent: any = '';
  images: any = [];
  firstPhoto: string = this.getFirstPhoto();

  constructor(private readonly carService: CarService) { }

  ngOnInit() {
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.setFirstPhoto(this.croppedImage);
  }
  imageLoaded() {
  }
  loadImageFailed() {
  }

  addCarImage() {
    if (this.croppedImage === null || this.croppedImage === '') {
    } else {
      this.images.push(this.croppedImage);
      this.imageChangedEvent = null;
      this.croppedImage = null;
    }
  }

  setFirstPhoto(firstPhoto: any): void {
    this.firstPhoto = firstPhoto;
  }

  getFirstPhoto(): any {
    return this.firstPhoto;
  }


}
