import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Car} from '../../entities/car';
import {CarService} from '../../services/car.service';
import {AppConstants} from '../../app.constants';
import {HttpErrorResponse} from '@angular/common/http';
import {AccountService} from '../../services/Account.service';
import {CookieService} from 'ngx-cookie-service';
import { FilterpricePipe } from 'src/app/pipes/filterprice.pipe';
import { FilterbrandPipe } from 'src/app/pipes/filterbrand.pipe';
import { FilterfuelPipe } from 'src/app/pipes/filterfuel.pipe';
import { FiltercolourPipe } from 'src/app/pipes/filtercolour.pipe';
import { FiltertradePipe } from 'src/app/pipes/filtertrade.pipe';
import { FilterunladenmassPipe } from 'src/app/pipes/filterunladenmass.pipe';
import { FilterPkPipe } from 'src/app/pipes/filter-pk.pipe';
import { FilterdateoffirstpermissionPipe } from 'src/app/pipes/filterdateoffirstpermission.pipe';
import { FiltercylindercapacityPipe } from 'src/app/pipes/filtercylindercapacity.pipe';
import {FilterVehicleCategoryPipe} from '../../pipes/filter-vehicle-category.pipe';
import { PlatformLocation } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FilterKmPipe } from 'src/app/pipes/filter-km.pipe';


@Component({
  selector: 'app-car-gallery',
  templateUrl: './car-gallery.component.html',
  styleUrls: ['./car-gallery.component.css'],

})
export class CarGalleryComponent implements OnInit {
  @ViewChild('button', {static: false}) myButton: ElementRef;
  cars;
  displayCars: Car[] = [];
  carsTop5: Object;
  categories: string[];
  brands: string[];
  fuelArray: string[];
  tradeNames: string[];
  colours: string[];
  cylinderCapacities: number[];

  // filter
  category = '';
  colour = '';
  fuel = '';
  cylinder = '';
  brand = '';
  trade = '';
  minPK = '';
  maxPK = '';
  minKM = '';
  maxKM = '';
  minPrice = '';
  maxPrice = '';
  maxMass = '';
  minMass = '';
  fromDate = '';
  ToDate = '';

  user: string;

  statusOffline: boolean;
  sizeTop5: number;
  favouritesList: Array<number> = [];
  favouriteCars = [];
  currentPage: number;

  private sub: any;



  constructor(private readonly carService: CarService
    , private readonly accountService: AccountService
    , private cookieService: CookieService, location: PlatformLocation, private route: ActivatedRoute) {
    const local = localStorage.getItem(AppConstants.LOCAL_STORAGE_KEY_SETTINGS);
    if (local != null) {
      this.user = local;
    }
    this.currentPage = Number(localStorage.getItem('currentPage'));
    this.getCarsTop5();

    this.sub = this.route.params.subscribe(params => {
      this.currentPage = +params['id']; // (+) converts string 'id' to a number
    });
  }

  async ngOnInit() {
    if (window.innerWidth >= 1280) {
      this.accountService.filter.next(true);
    }
    if (this.user != null) {
      await this.getCars('');
    } else {
      await this.getCars('online');
    }
    this.displayCars = this.cars.cars;
    this.getBrands();
    this.getTradeNames();
    this.getFuel();
    this.getColours();
    this.getNumberOfCarsInTop5();
    this.getCookieFilters();
  }



  formatNumber(num) {
    const num_parts = num.toString().split('.');
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return num_parts.join(',');
  }

  getCookieFilters(): void {
    this.category = this.cookieService.get('category');
    this.brand = this.cookieService.get('brand');
    this.colour = this.cookieService.get('colour');
    this.fuel = this.cookieService.get('fuel');
    this.cylinder = this.cookieService.get('cylinder');
    this.trade = this.cookieService.get('trade');
    this.minPK = this.cookieService.get('minPK');
    this.maxPK = this.cookieService.get('maxPK');
    this.minKM = this.cookieService.get('minKM');
    this.maxKM = this.cookieService.get('maxKM');
    this.minMass = this.cookieService.get('minMass');
    this.maxMass = this.cookieService.get('maxMass');
    this.minPrice = this.cookieService.get('minPrice');
    this.maxPrice = this.cookieService.get('maxPrice');
    this.fromDate = this.cookieService.get('fromDate');
    this.ToDate = this.cookieService.get('ToDate');
    this.updateFilters();
  }

  filterCars(skip: string = ''): Car[] {
    let filtered = this.cars.cars;

    if (skip !== 'category') {
      filtered = new FilterVehicleCategoryPipe().transform(filtered, this.category);
    }
    if (skip !== 'brand') {
      filtered = new FilterbrandPipe().transform(filtered, this.brand);
    }
    if (skip !== 'trade') {
      filtered = new FiltertradePipe().transform(filtered, this.trade);
    }
    if (skip !== 'cylinder') {
      filtered = new FiltercylindercapacityPipe().transform(filtered, this.cylinder);
    }
    if (skip !== 'fuel') {
      filtered = new FilterfuelPipe().transform(filtered, this.fuel);
    }
    if (skip !== 'colour') {
      filtered = new FiltercolourPipe().transform(filtered, this.colour);
    }
    filtered = new FilterpricePipe().transform(filtered, Number(this.minPrice), Number(this.maxPrice));
    filtered = new FilterdateoffirstpermissionPipe().transform(filtered, Number(this.fromDate), Number(this.ToDate));
    filtered = new FilterunladenmassPipe().transform(filtered, Number(this.minMass), Number(this.maxMass));
    filtered = new FilterKmPipe().transform(filtered, Number(this.minKM), Number(this.maxKM));
    return filtered || [];
  }

  setLastPage(value: any) {
    window.scroll(0, 0);
    localStorage.setItem('currentPage', value);
  }

  updateFilters() {
    this.displayCars = this.filterCars();
    this.getBrands();
    this.getTradeNames();
    this.getFuel();
    this.getColours();
    this.getCylinderCapacities();
    this.getCategories();
    // this.getNumberOfCarsInTop5();
    // this.getCookieFilters();
  }

  getCars(status: string): Promise<void> {
    return new Promise(resolve => {
      this.carService.getCars(status)
      .subscribe(cars => {
        cars.cars[0].summary = cars.cars[0].summary.replace(/\ufffd/g, '€').replace('�', '€');
        cars.cars.forEach(car => car.brand = car.brand.replace('�koda', 'Škoda'));
        cars.cars.forEach(car => car.brand = car.brand.replace('Citro�n', 'Citroën'));
        this.cars = cars;
        resolve();
      });
    });
  }


  getCategories(): void {
    this.categories = Array.from(new Set(this.cars.cars.map(car => car.vehicleCategory).sort()));
  }

  getBrands(): void {
    if (this.colour !== '' && this.trade === '' || this.fuel !== '' && this.trade === '') {
      this.brands = Array.from(new Set(this.filterCars('brand').map(car => car.brand))).sort();
    } else {
      this.brands = Array.from(new Set(this.cars.cars.map(car => {if (this.category !== '') {
        if (car.vehicleCategory === this.category) {
          return car.brand;
        }
      } else {
        return car.brand;
      }
      }).sort()));
    }
    for (let i = this.brands.length - 1; i >= 0; i--) {
      if(this.brands[i] === undefined) {
        this.brands.splice(i, 1);
      }
    }
  }

  getColours(): void {
    this.colours = Array.from(new Set(this.filterCars('colour').map(car => car.colour))).sort();
  }

  getFuel(): void {
    this.fuelArray = Array.from(new Set(this.filterCars('fuel').map(car => car.fuel))).sort();
  }

  getTradeNames(): void {
    this.tradeNames = Array.from(new Set(this.filterCars('trade').map(car => car.tradeName))).sort();
  }

  getCylinderCapacities(): void {
    this.cylinderCapacities = Array.from(new Set(this.filterCars('cylinder').map(car => Number(car.cylinderCapacity)))).sort();
  }

  cylinderCapacitiesContainsBetween(min = 0, max = Infinity) {
    return (this.cylinderCapacities || []).some(capacity =>
      capacity >= min && capacity <= max);
  }

  addFavouriteCar(car: Car): void {
    if (car.isFavourite) {
      if ((this.favouritesList.indexOf(car.carId) === -1)) {
        this.favouritesList.push(car.carId);
      }
    } else {
      this.favouritesList.splice(this.favouritesList.indexOf(car.carId), 1);
    }
  }

  getCarsTop5(): void {
    this.carService.getCarsTop5()
      .subscribe(carsTop5 => {
        this.carsTop5 = carsTop5;
        this.getNumberOfCarsInTop5();
      }, err => this.handleTokenError(err));
  }

  getNumberOfCarsInTop5() {
    this.carService.getNumberOfCarsInTop5().subscribe(sizeTop5 => this.sizeTop5 = sizeTop5);
  }

  checkIfCarIsInTop5(carId, top5cars): boolean {
    for (let i = 0; i < top5cars.length; i++) {
      if (carId === top5cars[i].carId) {
        return true;
      }
    }
    return false;
  }

  getCarById(car: Car) {
    if ((this.favouritesList.indexOf(car.carId) > -1)) {
      this.favouriteCars.push(car);
    } else {
      this.favouriteCars.splice(this.favouriteCars.indexOf(car), 1);
    }
  }

  setValue(value, car, top5cars) {
    if (!this.checkIfCarIsInTop5(car.carId, top5cars)) {
      value = !value;
      this.editOnlineStatus(value, car);
    } else {
      this.statusOffline = true;
    }
  }

  handleSuccessChange(data) {
    const newCarArray = [];
    this.cars.cars.forEach(car => {
      if (car.carId === data.cars[0].carId) {
        newCarArray.push(data.cars[0]);
      } else {
        newCarArray.push(car);
      }
    });
    this.cars.cars = newCarArray;
  }

  editOnlineStatus(status, car) {
    this.carService.editOnlineStatus(status, car.carId)
      .subscribe((data) => this.handleSuccessChange(data), err => this.handleTokenError(err));
  }

  updateTop5(updatedCar) {
    const newCarArray = [];
    this.cars.cars.forEach(car => {
      if (car.carId === updatedCar.cars[0].carId) {
        newCarArray.push(updatedCar.cars[0]);
      } else {
        newCarArray.push(car);
      }
    });
    this.cars.cars = newCarArray;
    this.getCarsTop5();

  }

  toggleAside(val: boolean) {
    $('aside, #toggleAside').toggleClass('open');
    if (window.innerWidth <= 1280) {
      if (!val) {
        this.accountService.filter.next(true);
      } else {
        if (this.accountService.filter.getValue() && val) {
          this.accountService.filter.next(false);
        } else if (val) {
          this.accountService.filter.next(true);
        }
      }
    }
  }

  private handleTokenError(err: HttpErrorResponse) {
    const statusCode = 401;
    const location = AppConstants.BASE_URL + '/login?error=Token_expired';

    if (err.status === statusCode) {
      this.accountService.logout();
      window.location.assign(location);
    }
  }

  setCookie(name: string, value: any) {
    const date = new Date();
    date.setMinutes(date.getMinutes() + 30);
    this.cookieService.set(name, value, date, undefined, undefined, undefined, 'Strict');
  }


  clearFilters(skip: string) {
    if (skip === '') {
      this.category = '';
    }
    this.colour = '';
    this.fuel = '';
    this.cylinder = '';
    this.brand = '';
    this.trade = '';
    this.minPK = 'Minimum';
    this.maxPK = 'Maximum';
    this.minPrice = 'Minimum';
    this.maxPrice = 'Maximum';
    this.maxMass = 'Maximum';
    this.minMass = 'Minimum';
    this.fromDate = 'Vanaf';
    this.ToDate = 'Tot';
    this.minKM = 'Minimum';
    this.maxKM = 'Maximum';


    if (skip === '') {
      this.cookieService.delete('category');
    }
    this.cookieService.delete('colour');
    this.cookieService.delete('fuel');
    this.cookieService.delete('cylinder');
    this.cookieService.delete('brand');
    this.cookieService.delete('trade');
    this.cookieService.delete('minPK');
    this.cookieService.delete('maxPK');
    this.cookieService.delete('minPrice');
    this.cookieService.delete('maxPrice');
    this.cookieService.delete('maxMass');
    this.cookieService.delete('minMass');
    this.cookieService.delete('fromDate');
    this.cookieService.delete('ToDate');
    this.cookieService.delete('minKM');
    this.cookieService.delete('maxKM');

    if (skip === '') {
      this.updateFilters();
    }
  }

  splitText(val: string) {
    return val.split('*');
  }
}
