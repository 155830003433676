import {Injectable} from '@angular/core';
import {AppConstants} from '../app.constants';
import {HttpErrorResponse} from '@angular/common/http';
import {AccountService} from '../services/Account.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private readonly accountService: AccountService) {
  }


  checkTokenOnPageChange() {
    const token = this.accountService.retrieve();

    if (token.token === '') {
      this.handleTokenValid();
    } else {
      this.accountService.checkToken().subscribe(() => this.handleTokenValid(), err => this.handleTokenError(err));
    }

  }

  handleTokenError(err: HttpErrorResponse) {
    const statusCode = 401;
    const location = AppConstants.BASE_URL + 'login?error=Token_expired';

    if (err.status === statusCode) {
      this.accountService.logout();
      window.location.assign(location);
    }
  }

  private handleTokenValid() {
  }
}
