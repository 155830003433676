import {Pipe, PipeTransform} from '@angular/core';
import { Car } from '../entities/car';

@Pipe({
  name: 'filterPrice'
})
export class FilterpricePipe implements PipeTransform {

  transform(cars: any, minPrice: number, maxPrice: number): any {
    if (Number.isNaN(minPrice))
      minPrice = 0;
    if (Number.isNaN(maxPrice))
      maxPrice = Infinity;

    if (!cars)
      return cars;
    if ((minPrice && minPrice <= 0) && (maxPrice && maxPrice <= 0))
      return cars;
    
    return cars.filter((car: Car) =>
      (car.acquisitionValue >= minPrice && car.acquisitionValue <= maxPrice)
      || (car.acquisitionValue <= maxPrice && (!minPrice || minPrice <= 0))
      || (car.acquisitionValue >= minPrice && (!maxPrice || maxPrice <= 0)));
  }

}
