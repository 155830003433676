import {Component, OnInit} from '@angular/core';
import {CarService} from '../../services/car.service';
import {ActivatedRoute} from '@angular/router';
import {AppConstants} from '../../app.constants';
import {LicenseplateService} from '../../services/licenseplate.service';


@Component({
  selector: 'app-cardetails-page',
  templateUrl: './cardetails-page.component.html',
  styleUrls: ['./cardetails-page.component.css']
})
export class CardetailsPageComponent implements OnInit {

  cars: Object;
  user: string;
  images: string[] = [];
  alertStatus: boolean;
  alertPrice: boolean;

  constructor(private readonly imageRoute: ActivatedRoute,
              private readonly carService: CarService,
              private carRoute: ActivatedRoute,
              private licenseplateService: LicenseplateService
  ) {
    const local = localStorage.getItem(AppConstants.LOCAL_STORAGE_KEY_SETTINGS);
    if (local !== null) {
      this.user = local;
    }
  }

  ngOnInit() {
    if (this.user != null) {
      this.getCarsById('');
      this.getImagesById();
    } else {
      this.getCarsById('online');
      this.getImagesById();
    }
  }

  formatNumber(num) {
    const num_parts = num.toString().split('.');
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return num_parts.join(',');
  }

  getImagesById(): void {
    const carId = +this.carRoute.snapshot.paramMap.get('carId');
    this.carService.getImage(carId)
      .subscribe(images => this.images = images.images);
  }

  getCarsById(status: string): void {
    const carId = +this.carRoute.snapshot.paramMap.get('carId');
    this.carService.getCar(carId, status)
      .subscribe(cars => {
        cars.cars[0].summary = cars.cars[0].summary.replace(/\ufffd/g, '€').replace('�', '€');
        cars.cars.forEach(car => car.brand = car.brand.replace('�koda', 'Škoda'));
        cars.cars.forEach(car => car.brand = car.brand.replace('Citro�n', 'Citroën'));
        this.cars = cars;
      });
  }

  setAlertStatus(value: boolean) {
    this.alertStatus = value;
  }

  setAlertPrice(value: boolean) {
    this.alertPrice = true;
  }

  GetSidecodeLicenseplate(Licenseplate) {
    return this.licenseplateService.GetSidecodeLicenseplate(Licenseplate);
  }

  FormatLicenseplate(Licenseplate, Sidecode) {

    if (Licenseplate ==  null || Licenseplate === '') {
      return '00-00-00';
    }
    return this.licenseplateService.FormatLicenseplate(Licenseplate, Sidecode);
  }

  toggleInfo() {
    const info = $('#info');
    const btn = $('#toggleInfo');
    info.toggleClass('open');
    btn.text(
      info.hasClass('open')
        ? 'Sluiten'
        : 'RDW-Informatie');
  }

  formatDate(val: string) {
    if (val === null || val === '') {
      return 'onbekend';
    }

    return val
      .match(/(\d{4})(\d{2})(\d{2})/)
      .slice(1)
      .reverse()
      .join('-');
  }

  checkIfList(val: any){
    if (val.origin === 'autopleinhengelo') {
      return true;
    } else {
      return false;
    }
  }

  splitText(val: string) {
    return val.split('*');
  }

  checkCarAge(car: any) {
    const date = new Date();
    if (car.buildYear !== undefined) {
      if (date.getFullYear() - car.buildYear >= 12) {
        return true;
      }
    }
    if (car.dateOfFirstPermission !== undefined && car.dateOfFirstPermission !== '') {
      if (date.getFullYear() - car.dateOfFirstPermission.substr(0, 4) >= 12) {
        return true;
      }
    } else {
      return false;
    }
  }
}
