import {Component, OnInit} from '@angular/core';
import {AppConstants} from '../../app.constants';
import {AccountService} from '../../services/Account.service';
import {TokenService} from '../../services/token.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  title = 'TCleasing';
  user: string;
  route = '';

  constructor(private readonly accountService: AccountService, private readonly tokenService: TokenService) {
  }

  ngOnInit() {
    this.tokenService.checkTokenOnPageChange();
    this.route = window.location.pathname;
    const local = localStorage.getItem(AppConstants.LOCAL_STORAGE_KEY_SETTINGS);
    if (local != null) {
      this.user = local;
    }

    setTimeout(() => {
      if (window.scrollY > 1) {
        $('header').addClass('scrolled');
      }
      window.addEventListener('scroll', () => {
        if (window.scrollY > 1) {
          $('header').addClass('scrolled');
        } else {
          $('header').removeClass('scrolled');
        }
      });
    }, 0);
  }

  public logout(): void {
    this.accountService.logout();
  }

  toggleAside(val: boolean) {
    $('aside, #toggleAside').toggleClass('open');
    if (!val) {
      this.accountService.filter.next(true);
    } else {
      if (this.accountService.filter.getValue() && val) {
        this.accountService.filter.next(false);
      } else if (val) {
        this.accountService.filter.next(true);
      }
    }
  }
}


