import {Pipe, PipeTransform} from '@angular/core';
import { Car } from '../entities/car';

@Pipe({
  name: 'filterdateoffirstpermission'
})
export class FilterdateoffirstpermissionPipe implements PipeTransform {

  transform(cars: any, fromDate: number, ToDate: number): any {
    if (Number.isNaN(fromDate))
      fromDate = 0;
    if (Number.isNaN(ToDate))
      ToDate = Infinity;
    
    if (!cars)
      return cars;

    if (!fromDate && !ToDate)
      return cars;

    return cars.filter((car: Car) => {
      const date = formatYear(car.dateOfFirstPermission);
      return (date >= fromDate && date <= ToDate)
        || (date <= ToDate && (fromDate === undefined || fromDate <= 0))
        || (date >= fromDate && (ToDate === undefined || ToDate <= 0));
    });
  }

}

function formatYear(date) {
  let year = '';
  for (let i = 0; i < 4; i++) {
    const number = date.charAt(i);
    year += number;
  }
  return parseInt(year, 10);
}
