import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterVehicleCategory'
})
export class FilterVehicleCategoryPipe implements PipeTransform {

  transform(cars: any, vehicleCategory: string): any {
    if (!cars) {
      return cars;
    } else {
      if (!vehicleCategory) {
        return cars;
      } else if (vehicleCategory) {
        return cars.filter(function (car) {
          return car.vehicleCategory.toLowerCase().includes(vehicleCategory.toLowerCase());
        });
      }
    }
  }

}
