export class AppConstants {
  private static readonly LOCAL_STORAGE_BASE_KEY = "tcleasing";
  public static LOCAL_STORAGE_KEY_SETTINGS =
    AppConstants.LOCAL_STORAGE_BASE_KEY;

  public static SERVER_URL = "https://api.tcleasing.nl";
  // public static SERVER_URL = 'http://136.144.177.200:8503/tcleasing';
  // public static SERVER_URL = 'http://localhost:8080';

  public static BASE_URL = "";
  // public static BASE_URL = 'https://voorraad.tcleasing.nl/';
  // public static BASE_URL = 'http://136.144.177.200:4444/voorraad/';
  // public static BASE_URL = 'http://localhost:4200/';
}
