import {Component, OnInit} from '@angular/core';
import {AccountService} from '../../services/Account.service';
import {Account} from '../../entities/Account';
import {AppConstants} from '../../app.constants';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  private pageTitle: string;
  private user: string;
  private error: string;
  private showError: string;
  private errorText: boolean;

  constructor(private readonly accountService: AccountService, private readonly route: ActivatedRoute) {
    const parameters = 'error';
    const pageTitle = 'Login';

    this.route.queryParams.subscribe(params => {
      this.error = params[parameters];
    });
    this.pageTitle = pageTitle;
  }

  ngOnInit() {
    const local = localStorage.getItem(AppConstants.LOCAL_STORAGE_KEY_SETTINGS);
    const stringToCheck = 'Token_expired';
    const errorText = 'Uw sessie is verlopen. Log opnieuw in.';

    if (local != null) {
      this.user = local;
    }

    if (this.error === stringToCheck) {
      this.showError = errorText;
    }
  }

  logForm(value: any) {
    const account = new Account(value.user, value.password);
    this.accountService.login(account);
    setTimeout(() => {
      this.errorText = this.accountService.getErrorText();
    }, 200);
  }

  setErrorText(value: boolean) {
    this.errorText = value;
  }
}
