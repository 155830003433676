import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CarService} from '../../services/car.service';

@Component({
  selector: 'app-car-leasecalculator',
  templateUrl: './car-leasecalculator.component.html',
  styleUrls: ['./car-leasecalculator.component.css']
})
export class CarLeasecalculatorComponent implements OnInit {
  cars: Object;
  monthTerm: number;
  financing: number;
  finalTerm: number;
  duration: number;
  monthsPerYear = 12;
  interest = 0.0649;
  Math: any;
  buttonClicked = false;
  alert: boolean;
  alertText: string;
  year: number = new Date().getFullYear();
  carAge: number;

  constructor(private readonly route: ActivatedRoute, private readonly carService: CarService) {
    this.Math = Math;
  }

  ngOnInit() {
    const numberTerms = 0;
    const numberOfMonths = 12;

    this.getCarsById();
    this.monthTerm = numberTerms;
    this.finalTerm = numberTerms;
    this.duration = numberOfMonths;
  }

  getCarsById(): void {
    const carId = +this.route.snapshot.paramMap.get('carId');
    this.carService.getCar(carId, '')
      .subscribe(cars => this.cars = cars);
  }

  calculateLease(financing: number) {
    this.setAlert(false);
    const onehundred = 100;
    const one = 1;

    if ((this.finalTerm < 0) && (this.financing < 0)) {
      this.alert = true;
      this.alertText = 'Financiering en slottermijn mogen geen negatieve getallen zijn!';
    } else if (this.finalTerm < 0) {
      this.alert = true;
      this.alertText = 'Slottermijn mag geen negatief getal zijn!';
    } else if (this.financing < 0) {
      this.alert = true;
      this.alertText = 'Financiering mag geen negatief getal zijn!';
    } else if (this.finalTerm > this.financing) {
      this.alert = true;
      this.alertText = 'Slottermijn mag niet hoger zijn dan de financiering!';
    } else if (this.financing > financing) {
      this.alert = true;
      this.alertText = 'Het te financieren bedrag mag niet hoger zijn dat de aanschafwaarde';
    } else {
      if ((financing || this.duration) === 0) {
        this.monthTerm = 0;
      } else {
        this.monthTerm = this.Math.round((((this.finalTerm * this.interest) / this.monthsPerYear) +
          ((this.interest / this.monthsPerYear) /
            (one - this.Math.pow((one + this.interest / this.monthsPerYear), -this.duration))) *
          (this.financing - this.finalTerm)) * onehundred) / onehundred;
      }
    }
  }

  setButtonClicked(value: boolean) {
    this.buttonClicked = value;
  }

  setFinancing(value: number) {
    this.financing = value;
  }

  setAlert(value: boolean) {
    this.alert = value;
  }

  setCarAge(value: number){
    this.carAge = value;
  }
}
