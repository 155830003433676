import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import { Car } from "../entities/car";
import { AppConstants } from "../app.constants";
import { Tokens } from "../entities/Tokens";
import { SettingsImpl } from "../settings.model";
import { ReturnedImages } from "../returned-images";
import { MessageService } from "./message.service";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({ providedIn: "root" })
export class CarService {
  readonly carsUrl = AppConstants.BASE_URL + "/tcleasing/cars/";
  readonly carDeleteUrl = AppConstants.BASE_URL + "/tcleasing/cars/delete/";
  readonly carsEditUrl = AppConstants.BASE_URL + "/tcleasing/cars/edit/";
  readonly carsTop5Url = AppConstants.BASE_URL + "/tcleasing/cars/top5/";
  readonly carDeleteTop5Url =
    AppConstants.BASE_URL + "/tcleasing/cars/top5/delete/";
  readonly carAddTop5Url = AppConstants.BASE_URL + "/tcleasing/cars/top5/add/";
  readonly carsSizeTop5Url =
    AppConstants.BASE_URL + "/tcleasing/cars/top5/size/";
  readonly carsFavouritesUrl =
    AppConstants.BASE_URL + "/tcleasing/cars/favourites/";
  readonly deleteImageUrl =
    AppConstants.BASE_URL + "/tcleasing/cars/images/delete/";
  readonly uploadFilesUrl =
    AppConstants.BASE_URL + "/tcleasing/cars/fileupload/";
  readonly uploadThumbnailUrl =
    AppConstants.BASE_URL + "/tcleasing/cars/thumbnail";

  constructor(
    private readonly messageService: MessageService,
    private readonly http: HttpClient
  ) {}

  getCarsWithoutImages(status: string): Observable<Car[]> {
    const url = `${this.carsUrl}withoutImages`;
    return this.http.get<Car[]>(url + status).pipe(
      tap((_) => this.log("fetched all cars")),
      catchError(this.handleError("getCars", []))
    );
  }

  getCars(status: string): any {
    return this.http.get<Car[]>(this.carsUrl + status).pipe(
      tap((_) => this.log("fetched all cars")),
      catchError(this.handleError("getCars", []))
    );
  }

  editOnlineStatus(status: boolean, carId: number) {
    const token = this.retrieve().token;
    const url = `${
      this.carsEditUrl
    }${"status"}/${status}/${carId}?key=${token}`;
    return this.http.put(url, httpOptions);
  }

  editOnlineCheckbox(carId: number, online: boolean, checkbox: boolean) {
    const token = this.retrieve().token;
    const url = `${
      this.carsEditUrl
    }${"checkbox"}/${carId}/${online}/${checkbox}?key=${token}`;
    return this.http.put(url, httpOptions);
  }

  getBrands(): Observable<string[]> {
    const url = `${this.carsUrl}${"brands"}`;
    const fetchBrands = "fetched brands";
    const getBrands = "getBrands";

    return this.http.get<string[]>(url).pipe(
      tap((_) => this.log(fetchBrands)),
      catchError(this.handleError(getBrands, []))
    );
  }

  getFuel(): Observable<string[]> {
    const url = `${this.carsUrl}${"fuel"}`;
    const fetchFuel = "fetched fuel";
    const getFuel = "getFuel";
    return this.http.get<string[]>(url).pipe(
      tap((_) => this.log(fetchFuel)),
      catchError(this.handleError(getFuel, []))
    );
  }

  getTradeNames(): Observable<string[]> {
    const url = `${this.carsUrl}${"trade"}`;
    const fetchTN = "fetched Tradename";
    const getTradeNames = "getTradeNames";

    return this.http.get<string[]>(url).pipe(
      tap((_) => this.log(fetchTN)),
      catchError(this.handleError(getTradeNames, []))
    );
  }

  getColours(): Observable<string[]> {
    const url = `${this.carsUrl}${"colours"}`;
    const fetchColours = "fetched Colours";
    const getColours = "getColours";

    return this.http.get<string[]>(url).pipe(
      tap((_) => this.log(fetchColours)),
      catchError(this.handleError(getColours, []))
    );
  }

  getCarsTop5WithoutImages(): Observable<Car[]> {
    const fetchT5 = "fetched Top5";
    const getT5 = "getT5";
    const url = `${this.carsTop5Url}WithoutImages`;

    return this.http.get<Car[]>(url).pipe(
      tap((_) => this.log(fetchT5)),
      catchError(this.handleError(getT5, []))
    );
  }

  getCarsTop5(): Observable<Car[]> {
    const fetchT5 = "fetched Top5";
    const getT5 = "getT5";

    return this.http.get<Car[]>(this.carsTop5Url).pipe(
      tap((_) => this.log(fetchT5)),
      catchError(this.handleError(getT5, []))
    );
  }

  deleteCar(carId: number) {
    const token = this.retrieve().token;
    const url = `${this.carDeleteUrl}${carId}?key=${token}`;
    return this.http.delete(url, httpOptions);
  }

  deleteImages(registrationNUmber: string): Promise<any> {
    const token = this.retrieve().token;
    const url = `${this.deleteImageUrl}${registrationNUmber}?key=${token}`;
    return this.http.delete(url, httpOptions).toPromise();
  }

  deleteCarTop5(carId: number) {
    const token = this.retrieve().token;
    const url = `${this.carDeleteTop5Url}${carId}?key=${token}`;
    return this.http.delete(url, httpOptions);
  }

  addCarTop5(carId: number) {
    const token = this.retrieve().token;
    const url = `${this.carAddTop5Url}${carId}?key=${token}`;
    return this.http.post(url, httpOptions);
  }

  getImage(carId: number): Observable<ReturnedImages> {
    const url = `${this.carsUrl}images/${carId}`;
    return this.http.get<ReturnedImages>(url).pipe(
      tap((_) => this.log(`fetched car carId=${carId}`)),
      catchError(this.handleError<ReturnedImages>(`getCar carId=${carId}`))
    );
  }

  getCar(carId: number, status: string): any {
    const url = `${this.carsUrl}${carId}/`;
    const fetchedCarById = `fetched car carId=${carId}`;
    const getCarById = `getCar carId=${carId}`;

    return this.http.get(url + status).pipe(
      tap((_) => this.log(fetchedCarById)),
      catchError(this.handleError<Car>(getCarById))
    );
  }

  getNumberOfCarsInTop5(): Observable<number> {
    return this.http.get<number>(this.carsSizeTop5Url, httpOptions);
  }

  editVehicleSummary(carId, vehicleSummary) {
    const token = this.retrieve().token;
    const url = `${
      this.carsEditUrl
    }${"summary"}/${vehicleSummary}/${carId}?key=${token}`;
    return this.http.put(url, httpOptions);
  }

  editVehicleCategory(carId: number, vehicleCategory: string) {
    const token = this.retrieve().token;
    const url = `${
      this.carsEditUrl
    }${"category"}/${vehicleCategory}/${carId}?key=${token}`;
    return this.http.put(url, httpOptions);
  }

  editMinimumPrice(carId: number, minimumPrice: number): Observable<{}> {
    const token = this.retrieve().token;
    const url = `${
      this.carsEditUrl
    }${"minimum"}/${minimumPrice}/${carId}?key=${token}`;
    return this.http.put(url, httpOptions);
  }

  editColour(carId: number, colour: string) {
    const token = this.retrieve().token;
    const url = `${
      this.carsEditUrl
    }${"colour"}/${colour}/${carId}?key=${token}`;
    return this.http.put(url, httpOptions);
  }

  editExpiralDateAPK(carId: number, expiralDateAPK: string) {
    const token = this.retrieve().token;
    const url = `${
      this.carsEditUrl
    }${"expiral"}/${expiralDateAPK}/${carId}?key=${token}`;
    return this.http.put(url, httpOptions);
  }

  editUnladenMass(carId: number, unladenMassOfVehicle: number) {
    const token = this.retrieve().token;
    const url = `${
      this.carsEditUrl
    }${"mass"}/${unladenMassOfVehicle}/${carId}?key=${token}`;
    return this.http.put(url, httpOptions);
  }

  editSold(carId: number, sold: number) {
    const token = this.retrieve().token;
    const url = `${this.carsEditUrl}sold/${sold}/${carId}?key=${token}`;
    return this.http.put(url, httpOptions);
  }

  editCylinderCapacity(carId: number, cylinderCapacity: number) {
    const token = this.retrieve().token;
    const url = `${
      this.carsEditUrl
    }${"cylinderCapacity"}/${cylinderCapacity}/${carId}?key=${token}`;
    return this.http.put(url, httpOptions);
  }

  editNumberOfCylinders(carId: number, numberOfCylinders: number) {
    const token = this.retrieve().token;
    const url = `${
      this.carsEditUrl
    }${"numberOfCylinders"}/${numberOfCylinders}/${carId}?key=${token}`;
    return this.http.put(url, httpOptions);
  }

  sendEmailFavouriteCars(favouritesList: number[], email: String) {
    const url = `${this.carsFavouritesUrl}${email}?${this.getParams(
      favouritesList
    )}`;
    return this.http.post(url, httpOptions).subscribe();
  }

  uploadFiles(givenImages: any): Promise<any> {
    return this.http
      .post(this.uploadFilesUrl, givenImages, httpOptions)
      .toPromise();
  }

  uploadThumbnail(thumbnail: string, registrationNumber: string): Promise<any> {
    return this.http
      .post(
        `${this.uploadThumbnailUrl}/${registrationNumber}`,
        thumbnail,
        httpOptions
      )
      .toPromise();
  }

  private log(message: string) {
    const messageString = `CarService: ${message}`;

    this.messageService.add(messageString);
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      const errorMessage = `${operation} failed: ${error.message}`;

      this.log(errorMessage);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private retrieve(): Tokens {
    const json = localStorage.getItem(AppConstants.LOCAL_STORAGE_KEY_SETTINGS);
    if (json) {
      return JSON.parse(json);
    } else {
      return new SettingsImpl();
    }
  }

  private getParams(query) {
    let params: HttpParams = new HttpParams();
    for (const key of Object.keys(query)) {
      if (query[key]) {
        if (query[key] instanceof Array) {
          query[key].forEach((item) => {
            params = params.append(`${key.toString()}[]`, item);
          });
        } else {
          params = params.append("favouritesList", query[key]);
        }
      }
    }
    return params;
  }
}
