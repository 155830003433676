import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterBrand'
})
export class FilterbrandPipe implements PipeTransform {

  transform(cars: any, brand: string): any {
    if (!cars) {
      return cars;
    } else {
      if (!brand) {
        return cars;
      } else if (brand !== undefined) {
        return cars.filter(function (car) {
          return car.brand.toLowerCase().includes(brand.toLowerCase());
        });
      }
    }
  }

}
