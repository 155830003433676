import {Component, OnInit} from '@angular/core';
import {CarService} from '../../services/car.service';
import {AppConstants} from '../../app.constants';
import {HttpErrorResponse} from '@angular/common/http';
import {AccountService} from '../../services/Account.service';

@Component({
  selector: 'app-administrator-page',
  templateUrl: './administrator-page.component.html',
  styleUrls: ['./administrator-page.component.css']
})
export class AdministratorPageComponent implements OnInit {

  user: string;
  cars: Object;
  carToBeEdited: Object;
  carsTop5: Object;
  sizeTop5: number;
  disabled: boolean;
  images: Object;
  statusOffline: boolean;
  statusTop5: boolean;
  searchString: string;

  constructor(private readonly carService: CarService, private readonly accountService: AccountService) {
  }

  ngOnInit() {
    this.getCarsWithoutImages();
    this.getCarsTop5WithoutImages();
    this.getNumberOfCarsInTop5();

    const local = localStorage.getItem(AppConstants.LOCAL_STORAGE_KEY_SETTINGS);
    if (local != null) {
      this.user = local;
    }
  }

  confirmDelete(carId, registrationNumber): void {
    const result = confirm('Weet u zeker dat u de auto wilt verwijderen?');

    if (result) {
      this.deleteImages(registrationNumber);
      this.deleteCarTop5(carId);
      this.deleteCar(carId);
    }
    this.disabled = false;
  }

  getCarsWithoutImages(): void {
    setTimeout(() => {
      this.carService.getCarsWithoutImages('')
        .subscribe(cars => {
          this.cars = cars;
        });
    }, 200);
  }

  deleteCar(carId: number) {
    this.carService.deleteCar(carId)
      .subscribe(cars => this.cars = cars, err => this.handleTokenError(err));
    setTimeout(() => {
      this.getCarsWithoutImages();
    }, 1000);
  }

  addCarTop5(carId: number, status: boolean) {
    const maxTop5 = 5;
    if (status) {
      if (this.sizeTop5 < maxTop5) {
        this.carService.addCarTop5(carId)
          .subscribe(cars => this.cars = cars, err => this.handleTokenError(err));
        setTimeout(() => {
          this.getCarsTop5WithoutImages();
          this.getCarsWithoutImages();
        }, 300);
      }
    } else {
      this.statusTop5 = true;
    }
  }

  getCarsTop5WithoutImages(): void {
    setTimeout(() => {
      this.carService.getCarsTop5WithoutImages()
        .subscribe(carsTop5 => {
          this.carsTop5 = carsTop5;
        }, err => this.handleTokenError(err));
    }, 100);
  }


  checkIfCarIsInTop5(carId, top5cars, amountOfCars): boolean {
    for (let i = 0; i < amountOfCars; i++) {
      if (carId === top5cars[i].carId) {
        return true;
      }
    }
    return false;
  }

  editOnlineStatus(status, carId: number) {
    this.carService.editOnlineStatus(status, carId)
      .subscribe(carToBeEdited => this.carToBeEdited = carToBeEdited, err => this.handleTokenError(err));
  }

  deleteImages(registrationNumber: string) {
    this.carService.deleteImages(registrationNumber).then(images => this.images = images, err => this.handleTokenError(err));
  }

  deleteCarTop5(carId: number) {
    this.carService.deleteCarTop5(carId)
      .subscribe(cars => this.cars = cars, err => this.handleTokenError(err));
    setTimeout(() => {
      this.getCarsTop5WithoutImages();
      this.getCarsWithoutImages();
    }, 300);
  }

  getNumberOfCarsInTop5() {
    this.carService.getNumberOfCarsInTop5().subscribe(sizeTop5 => this.sizeTop5 = sizeTop5);
  }

  setValue(value, carId: number, top5cars, amountOfCars) {
    if (!this.checkIfCarIsInTop5(carId, top5cars, amountOfCars)) {
      if (!value) {
        value = true;
      } else {
        value = false;
      }
      this.editOnlineStatus(value, carId);
    } else {
      this.statusOffline = true;
    }
  }

  handleTokenError(err: HttpErrorResponse) {
    const statusCode = 401;
    const location = AppConstants.BASE_URL + '/login?error=Token_expired';

    if (err.status === statusCode) {
      this.accountService.logout();
      window.location.assign(location);
    }
  }

  setStatusOffline(status: boolean) {
    this.statusOffline = status;
  }

  setStatusTop5(status: boolean) {
    this.statusTop5 = status;
  }
}