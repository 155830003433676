import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AdministratorPageComponent} from './components/administrator-page/administrator-page.component';
import {CarGalleryComponent} from './components/car-gallery/car-gallery.component';

import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {CardetailsPageComponent} from './components/cardetails-page/cardetails-page.component';
import {AppRoutingModule} from './app-routing.module';
import {LoginComponent} from './components/login/login.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {AddCarComponent} from './components/add-car/add-car.component';
import {CarCarouselComponent} from './components/car-carousel/car-carousel.component';
import {CarLeasecalculatorComponent} from './components/car-leasecalculator/car-leasecalculator.component';
import {FilterbrandPipe} from './pipes/filterbrand.pipe';
import {FilterpricePipe} from './pipes/filterprice.pipe';
import {FilterfuelPipe} from './pipes/filterfuel.pipe';
import {FiltercolourPipe} from './pipes/filtercolour.pipe';
import {FiltertradePipe} from './pipes/filtertrade.pipe';
import {FilterunladenmassPipe} from './pipes/filterunladenmass.pipe';
import {FiltercylindercapacityPipe} from './pipes/filtercylindercapacity.pipe';
import {FilterdateofAPKPipe} from './pipes/filterdateof-apk.pipe';
import {FilterdateoffirstpermissionPipe} from './pipes/filterdateoffirstpermission.pipe';
import {FavouriteCarComponent} from './components/favourite-car/favourite-car.component';
import {SlideshowModule} from 'ng-simple-slideshow';
import {ImageCropperModule} from 'ngx-image-cropper';
import {NgxPaginationModule} from 'ngx-pagination';
import {AppComponent} from './components/app/app.component';
import {EditPageComponent} from './components/edit-page/edit-page.component';
import {FilterPkPipe} from './pipes/filter-pk.pipe';
import {SearchPipe} from './pipes/search.pipe';
import {TruncateModule} from 'ng2-truncate';
import {CookieService} from 'ngx-cookie-service';
import {DataPrivacyComponent} from './components/data-privacy/data-privacy.component';
import {PhotocropperComponent} from './components/photocropper/photocropper.component';
import { FilterKmPipe } from './pipes/filter-km.pipe';
@NgModule({
  declarations: [ // Components & pipes get declared here
    AppComponent,
    AddCarComponent,
    AdministratorPageComponent,
    CarGalleryComponent,
    CardetailsPageComponent,
    LoginComponent,
    NavbarComponent,
    CarCarouselComponent,
    CarLeasecalculatorComponent,
    FilterbrandPipe,
    FilterpricePipe,
    FilterfuelPipe,
    FiltercolourPipe,
    FiltertradePipe,
    FilterunladenmassPipe,
    FiltercylindercapacityPipe,
    FilterdateofAPKPipe,
    FilterdateoffirstpermissionPipe,
    FavouriteCarComponent,
    EditPageComponent,
    FilterPkPipe,
    FilterKmPipe,
    SearchPipe,
    DataPrivacyComponent,
    PhotocropperComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    SlideshowModule,
    ImageCropperModule,
    NgxPaginationModule,
    TruncateModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
