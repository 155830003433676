import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CarService} from '../../services/car.service';
import {RDWService} from '../../services/rdw.service';
import {Car} from '../../entities/car';
import * as $ from 'jquery';
import {AppConstants} from '../../app.constants';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-car',
  templateUrl: './add-car.component.html',
  styleUrls: ['./add-car.component.css']
})
export class AddCarComponent implements OnInit {
  firstPhoto: string = this.getFirstPhoto();
  registrationNumber: string = this.getRegistrationNumber();
  readonly RDWvehicleCategory: string = this.getVehicleCategory();
  summary: string = this.getSummary();
  cars: Object;
  minimumPrijs: number;
  showForm = false;
  carType: number;
  readonly pageTitle: string;
  user: string;
  width: number;
  imageChangedEvent: any = '';
  croppedImage: any = null;
  images: any = [];
  beingCropped = false;
  unknownCar = false;

  constructor(readonly http: HttpClient,
              private readonly carService: CarService,
              private readonly RDWservice: RDWService,
              private router: Router
  ) {
    this.pageTitle = 'Auto toevoegen';
  }

  ngOnInit() {
    this.getCars();
    const local = localStorage.getItem(AppConstants.LOCAL_STORAGE_KEY_SETTINGS);
    if (local != null) {
      this.user = local;
    }
  }

  getCars(): void {
    setTimeout(() => {
      this.carService.getCars('')
        .subscribe(cars => {
          this.cars = cars;
        });
    }, 150);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.setFirstPhoto(this.croppedImage);
  }

  imageLoaded() {
  }

  loadImageFailed() {
  }

  addCarImage() {
    if (this.croppedImage !== null || this.croppedImage !== '') {
      this.images.push(this.croppedImage);
      this.imageChangedEvent = '';
      this.croppedImage = null;
      this.beingCropped = false;
      $('#imagePicker').val('');
    }
  }

  openImagePicker() {
    $('#imagePicker').click();
    this.beingCropped = true;
  }

  sendData(input: string, online, redirect: boolean): void {
    const valuearray: string[] = ['kenteken', 'voertuigsoort', 'merk', 'handelsbenaming', 'eerste_kleur', 'aantal_zitplaatsen',
      'aantal_deuren', 'vervaldatum_apk', 'datum_eerste_toelating', 'cilinderinhoud', 'aantal_cilinders',
      'massa_ledig_voertuig'];
    let i;
    const RDWservice = this.getRDWservice();
    const showValue: boolean = this.getShowForm();
    const summary: string = this.summary;
    const minimumPrice: number = this.getMinimumPrice();
    const sold = 0;
    const setUnknownCar = (value) => this.setUnknown(value);
    const showForm = (value) => this.setShowForm(value);
    const firstPhoto = this.images[0];
    const isFavourite = false;
    const carType = this.getCarType();
    const onUpload = () => this.onUpload();
    if (input !== '') {
      input = input.toUpperCase();
      $.ajax({
        url: 'https://opendata.rdw.nl/resource/8ys7-d773.json?$select=brandstof_omschrijving,nettomaximumvermogen&kenteken=' + input,
        type: 'GET',
        dataType: 'json',
        success(fuel) {
          $.ajax({
            url: 'https://opendata.rdw.nl/resource/m9d7-ebf2.json?$select=kenteken,voertuigsoort,merk,handelsbenaming,' +
              'eerste_kleur,aantal_zitplaatsen,aantal_deuren,vervaldatum_apk,datum_eerste_toelating,cilinderinhoud,' +
              'aantal_cilinders,massa_ledig_voertuig&kenteken=' + input,
            type: 'GET',
            dataType: 'json',
            success(data) {
              setUnknownCar(false);
              for (i = 0; i < valuearray.length; i++) {
                if (fuel['0'] === undefined) {
                  if (data['0'] === undefined) {
                    setUnknownCar(true);
                    showForm(false);
                    return;
                  }
                  fuel['0'] = {
                    brandstof_omschrijving: 'Onbekend',
                    nettomaximumvermogen: '0'
                  };
                }
                document.getElementById('fuel').innerText = fuel['0']['brandstof_omschrijving'];
                document.getElementById('nettomaximumvermogen').innerText = (Math.round(
                  parseFloat(fuel['0']['nettomaximumvermogen']) * 1.36)).toString();
                if ((Math.round(
                  parseFloat(fuel['0']['nettomaximumvermogen']) * 1.36)).toString() === '0') {
                  document.getElementById('nettomaximumvermogen').innerText = 'Onbekend';
                }
                if ((i !== 8) && (i !== 7)) {
                  document.getElementById(valuearray[i]).innerText = data['0'][valuearray[i]];
                } else {
                  document.getElementById(valuearray[i]).innerText = data['0'][valuearray[i]].slice(6, 8) + '-' +
                    data['0'][valuearray[i]].slice(4, 6) + '-' + data['0'][valuearray[i]].slice(0, 4);
                }
              }
              if (showValue === true && redirect) {
                const car = new Car(data['0']['kenteken'], data['0']['voertuigsoort'], data['0']['merk'], data['0']['handelsbenaming'],
                  data['0']['eerste_kleur'], parseInt(data['0']['aantal_zitplaatsen'], 10), parseInt(data['0']['aantal_deuren'], 10),
                  data['0']['vervaldatum_apk'], data['0']['datum_eerste_toelating'], data['0']['cilinderinhoud'],
                  data['0']['aantal_cilinders'], parseInt(data['0']['massa_ledig_voertuig'], 10), fuel['0']['brandstof_omschrijving'],
                  summary, minimumPrice, sold, firstPhoto, online, Date.now().toString(), isFavourite,
                  Math.round(parseFloat(fuel['0']['nettomaximumvermogen']) * 1.36), carType, 0);
                RDWservice.sendData(car).subscribe(value => {
                  this.car = value;
                  if (redirect) {
                    onUpload();
                  }
                });
              }
            }
          });
        }
      });
    }
  }

  setData(licenseplate: string, price: string, summary: string, checkbox: any) {
    this.setSummary(summary);
    this.setRegistrationNumber(licenseplate);
    this.setMinimumPrice(price);
    this.setCarType(checkbox);
  }

  setCarType(value: any): void {
    this.carType = value;
  }

  setUnknown(value: any): void {
    this.unknownCar = value;
  }

  getCarType() {
    return this.carType;
  }

  getFirstPhoto(): any {
    return this.firstPhoto;
  }

  setFirstPhoto(firstPhoto: any): void {
    this.firstPhoto = firstPhoto;
  }

  setMinimumPrice(value: string): void {
    this.minimumPrijs = parseInt(value, 10);
  }

  getMinimumPrice(): number {
    return this.minimumPrijs;
  }

  getSummary(): string {
    return this.summary;
  }

  setSummary(summary: string): void {
    this.summary = summary;
  }

  getShowForm(): boolean {
    return this.showForm;
  }

  setShowForm(value: boolean): void {
    this.showForm = value;
  }

  getRDWservice(): RDWService {
    return this.RDWservice;
  }

  getRegistrationNumber(): string {
    return this.registrationNumber;
  }

  setRegistrationNumber(value: string) {
    this.registrationNumber = value;
  }

  getVehicleCategory(): string {
    return this.RDWvehicleCategory;
  }

  onUpload() {
    const givenImages = {
      'registrationNumber': this.registrationNumber,
      'files': this.images
    };
    this.carService.uploadFiles(givenImages).then(() => this.router.navigateByUrl('/'));
  }

  deleteImage(image: any) {
    this.images.splice(this.images.indexOf(image), 1);
  }

  registrationNumberExists(cars, registrationNumber) {
    if (registrationNumber === undefined || registrationNumber === '' || cars === null) {
      return;
    }
    for (let i = 0; i < cars.length; i++) {
      if (cars[i].registrationNumber.toLowerCase() === registrationNumber.toLowerCase()) {
        return true;
      }
    }
    return false;
  }
}
